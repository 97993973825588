<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
    <div>
        <div class="sld_order_list">
            <MemberTitle :memberTitle="'月度采购计划'"></MemberTitle>
            <div class="container">
                <h3>月度计划</h3>
                <div class="headerSearch3">
                    <el-form ref="ruleFormRef" :model="param" :inline="true" class="demo-form-inline">
                        <!--<el-form-item label="采购来源" prop="source">
                <el-select v-model="param.source" placeholder="" style="width:210px ">
                  <el-option label="用友" value="0" />
                  <el-option
                    :label="platform == 1 ? '大石工采' : '大石集采'"
                    value="1"
                  />
                </el-select>
              </el-form-item>-->
                        <el-form-item label="采购单号" prop="memberPlanCode">
                            <el-input v-model="param.memberPlanCode" style="width: 195px" />
                        </el-form-item>

                        <span class="demonstration" style="color: #333333; font-size: 13px">月度</span>
                        <el-date-picker
                            v-model="param.date"
                            type="month"
                            placeholder="请选择月份"
                            style="width: 195px; margin-left: 10px"
                            format="YYYY-MM"
                            value-format="YYYY-MM"
                        />
                        <!--<div style="width: 195px"></div>-->
                        <!--<el-form-item
              label="计划状态"
              prop="state"
              :style="'margin-left: 10px'"
            >
              <el-select v-model="param.state" placeholder="">
                <el-option label="待报价" value="0" />
                <el-option label="报价中" value="1" />
                <el-option label="已报价" value="2" />
                <el-option label="已关闭" value="3" />
              </el-select>
            </el-form-item>-->
                        <el-form-item label="商品名称" prop="goodsName" style="margin-left: 10px">
                            <el-input v-model="param.goodsName" style="width: 195px" />
                        </el-form-item>
                        <!--<el-form-item>-->
                        <div class="searchBtn">
                            <div class="button" @click="onSubmit(ruleFormRef)">搜索</div>
                            <el-button @click="resetForm(ruleFormRef)">重置</el-button>
                        </div>
                        <!--</el-form-item>-->
                    </el-form>
                </div>
                <!-- <div></div> -->
                <!-- 部门 -->
                <div class="sld_order_button">
                    <div class="button2" @click="addPlan">新增</div>
                    <!--<el-tabs
            v-model="activeName"
            class="demo-tabs"
            @tab-click="handleClick"
            v-for=" item,index in deptList.data"
            :key="index"
          >
            <el-tab-pane :label="item.deptName" :name="item.deptId"></el-tab-pane>
          </el-tabs>-->
                    <el-scrollbar>
                        <div class="tabList pointer">
                            <div
                                v-for="(item, index) in deptList.data"
                                :key="index"
                                :class="activeName == item.deptId ? 'active tabItem' : 'tabItem'"
                                @click="changeTab(item.deptId)"
                            >
                                {{ item.deptName }}
                            </div>
                        </div>
                    </el-scrollbar>
                    <!--<div class="sld_order_newdepartments" @click="newdepartments">
            + 新增部门
          </div>-->
                </div>
                <div class="sld_List">
                    <div class="order_item" v-for="(orderItem, index) in tableData.data" :key="index">
                        <div class="titles flex_row_between_center">
                            <div class="flex_row_start_centers">
                                <!-- <div class="left">
                  <img v-if="orderItem.source == 0" src="@/assets/yongyou.png" />

                  <img v-if="orderItem.source == 1" src="@/assets/dashiLogo.png"/>
                  <img v-if="orderItem.source == 2" src="@/assets/dashijicai@2x.png"/>
                </div> -->
                                <div class="order_num">
                                    <!--@click="goDetail(orderItem.memberOrderCode)"-->
                                    <span class="titleColor"> 采购单号 </span>:{{ orderItem.memberPlanCode }}
                                </div>
                                <!-- <div>
                  <span class="titleColor">创建时间</span>:{{
                    orderItem.createTime
                  }}
                </div> -->
                                <div class="order_num">
                                    <span class="titleColor">到货日期：</span>{{ orderItem.prayDate }}
                                </div>
                                <div class="order_num" style="display: flex; align-items: center">
                                    <span class="titleColor">创建人</span>:
                                    <p style="display: inline-block">{{ orderItem.prayName }}</p>
                                    <!--<el-tooltip

                            class="box-orderItem"
                            effect="dark"
                            :content="orderItem.prayName"
                            placement="top-start"
                          >
                            <span class="fontColor text-ellipsiss" style="max-width:50px">{{ orderItem.prayName }}</span>
                          </el-tooltip>-->
                                    <!--<p>{{ orderItem.operator }}</p>-->
                                </div>
                                <div class="order_num" style="display: flex; align-items: center">
                                    <span class="titleColor">创建日期</span> :
                                    <span style="display: inline-block">{{ orderItem.createTime }}</span>
                                </div>
                                <div class="order_num" style="display: flex; align-items: center">
                                    <span class="titleColor">备注：</span>
                                    <span
                                        :title="orderItem.desc"
                                        style="max-width: 40px; display: inline-block"
                                        v-if="orderItem.desc.split('').length <= 3"
                                        >{{ orderItem.desc ? orderItem.desc : "无" }}</span
                                    >
                                    <el-tooltip
                                        v-else
                                        class="box-orderItem"
                                        effect="dark"
                                        :content="orderItem.desc"
                                        placement="top-start"
                                    >
                                        <span class="fontColor text-ellipsiss" style="width: 40px">{{
                                            orderItem.desc
                                        }}</span>
                                    </el-tooltip>
                                </div>
                                <div class="orderPayTyp_eName">
                                    <span>{{ orderItem.type == 1 ? "月度采购" : "单次采购" }}</span>
                                </div>
                            </div>
                            <div style="display: flex; justify-content: flex-end; align-items: center">
                                <!--v-if="orderItem.isFillIn == 0"-->
                                <div
                                    v-if="
                                        ((store.state.memberInfo.isLookOrder &&
                                            store.state.memberInfo.memberUserType == 2) ||
                                            store.state.memberInfo.memberUserType == 1 ||
                                            store.state.memberInfo.memberUserType == 0) &&
                                        orderItem.state !== 3 &&
                                        orderItem.state !== 4
                                    "
                                    class="inviteMySupplier"
                                    @click="getmySupplierList(null, orderItem.memberPurchaseNeedRespList)"
                                >
                                    邀请我的供应商
                                </div>
                                <!--<div v-if="orderItem.isFillIn == 0&&store.state.memberInfo.isNeedMyPrice==1" class="hintTagss">
                未填我的报价
              </div>-->

                                <p v-if="orderItem.state == 4" class="approvaling" style="margin-left: 10px">审批中</p>
                                <router-link
                                    target="_blank"
                                    :to="{
                                        path: '/member/procurement/procuremmentPlanDetail',
                                        query: {
                                            memberPlanCode: orderItem.memberPlanCode,
                                            goodsNum: orderItem.memberPurchaseNeedRespList.length,
                                            pageNum: param.pageNum,
                                            pageSize: param.pageSize,
                                            type: 1
                                        }
                                    }"
                                >
                                    <div class="toMonthlyDetail" v-if="orderItem.state !== 4">查看报价</div>
                                </router-link>
                            </div>
                        </div>

                        <div
                            class="good_info_con flex_row_start_center"
                            :style="
                                indexs <= orderItem.num
                                    ? 'border-top: 1px solid #e8e8f1;;'
                                    : 'border-top: 1px solid #e8e8f1; display:none'
                            "
                            v-for="(goodsItem, indexs) in orderItem.memberPurchaseNeedRespList"
                            :key="indexs"
                        >
                            <div
                                class="good_infos flex_column_center_start"
                                :style="
                                    ((store.state.memberInfo.isLookOrder &&
                                        store.state.memberInfo.memberUserType == 2) ||
                                        store.state.memberInfo.memberUserType == 1 ||
                                        store.state.memberInfo.memberUserType == 0) &&
                                    orderItem.state !== 4
                                        ? ''
                                        : 'width:100%'
                                "
                            >
                                <div class="items flex_row_start_center">
                                    <div class="goods flex_row_start_center">
                                        <div class="rights">
                                            <div class="overflow_ellipsis_two">
                                                <span class="titleColor">{{ indexs + 1 }}</span>
                                            </div>
                                            <div
                                                class="good_names overflow_ellipsis_two"
                                                @click="goGoodsDetail(goodItem.skuCode)"
                                            >
                                                <!--<span goodsItem.goodsName.split('').length < 15?>{{ goodsItem.goodsName }}</span>-->
                                                <span
                                                    :title="goodsItem.goodsName"
                                                    style="max-width: 200px; display: inline-block"
                                                    v-if="goodsItem.goodsName.split('').length < 15"
                                                    >{{ goodsItem.goodsName }}</span
                                                >
                                                <el-tooltip
                                                    v-else
                                                    class="box-goodsItem"
                                                    effect="dark"
                                                    :content="goodsItem.goodsName"
                                                    placement="top-start"
                                                >
                                                    <span class="fontColor text-ellipsiss" style="max-width: 200px">{{
                                                        goodsItem.goodsName
                                                    }}</span>
                                                </el-tooltip>
                                            </div>
                                            <div class="overflow_ellipsis_two">
                                                <span class="titleColor">规格型号</span>：<span
                                                    >{{ goodsItem.goodsMarque }}
                                                </span>
                                            </div>
                                            <div class="overflow_ellipsis_two">
                                                <span class="titleColor">采购数量</span>：<span class="blue"
                                                    >{{ goodsItem.purchaseNum }}{{ goodsItem.goodsUnit }}
                                                </span>
                                            </div>
                                            <div class="overflow_ellipsis_two">
                                                <img
                                                    :src="checkFiles"
                                                    v-if="goodsItem.purchasePic"
                                                    style="width: 10px; height: 10px; cursor: pointer; margin-top: 3px"
                                                    @click="previewImg(goodsItem.purchasePic)"
                                                />
                                            </div>
                                            <div class="overflow_ellipsis_two">
                                                <span class="titleColor">部门</span>：<span
                                                    >{{ orderItem.deptName ? orderItem.deptName : "无" }}
                                                </span>
                                            </div>
                                            <div
                                                class="overflow_ellipsis_two"
                                                style="display: flex; align-items: center"
                                            >
                                                <span class="titleColor">备注</span>：
                                                <!--<span
                          >{{ goodsItem.desc ? orderItem.desc : "无" }}
                        </span>-->
                                                <span
                                                    :title="goodsItem.desc"
                                                    style="max-width: 200px; display: inline-block"
                                                    v-if="goodsItem.desc.split('').length < 15"
                                                    >{{ goodsItem.desc ? goodsItem.desc : "无" }}</span
                                                >
                                                <el-tooltip
                                                    v-else
                                                    class="box-goodsItem"
                                                    effect="dark"
                                                    :content="goodsItem.desc"
                                                    placement="top-start"
                                                >
                                                    <span class="fontColor text-ellipsiss" style="max-width: 60px">{{
                                                        goodsItem.desc
                                                    }}</span>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="after flex_row_center_center">--</div> -->
                            <div class="order_price flex_row_center_center" style="width: 8%">
                                <div v-if="goodsItem.isPlaceAnOrder == 1" class="green fontStyle">已下单</div>
                                <div v-if="goodsItem.isPlaceAnOrder == 0" class="yellow fontStyle">未下单</div>
                            </div>
                            <div
                                v-if="
                                    (store.state.memberInfo.isLookOrder &&
                                        store.state.memberInfo.memberUserType == 2) ||
                                    store.state.memberInfo.memberUserType == 1 ||
                                    store.state.memberInfo.memberUserType == 0
                                "
                                class="oprate flex_column_center_center"
                                style="
                                    padding: 0px 5px;
                                    display: flex;
                                    width: 22%;
                                    flex-direction: row;
                                    justify-content: center;
                                "
                            >
                                <!--<span class="btnDiv"   @click="createOrder(orderItem,goodsItem)"  v-if="goodsItem.isPlaceAnOrder == 0">
                  下单
                </span>-->
                                <span
                                    class="btnDiv"
                                    @click="getmySupplierList(goodsItem.memberNeedCode, [])"
                                    v-if="
                                        (goodsItem.state == 1 || goodsItem.state == 2 || goodsItem.state == 0) &&
                                        orderItem.state !== 4 &&
                                        orderItem.state !== 3
                                    "
                                >
                                    邀请我的供应商
                                </span>
                                <span
                                    class="btnDiv"
                                    @click="close(goodsItem.memberNeedCode, orderItem.memberPlanCode)"
                                    v-if="goodsItem.state !== 3"
                                >
                                    关闭
                                </span>
                                <span class="btnDiv" v-if="goodsItem.state == 3"> 已关闭 </span>
                            </div>
                        </div>
                        <div class="AllGoodsList" v-if="orderItem.memberPurchaseNeedRespList.length > 5">
                            <div v-if="orderItem.num == 4" @click="spreadGoodsList(orderItem)">
                                <span>查看全部商品 </span> <img :src="unfold" />
                            </div>
                            <div v-else @click="spreadGoodsList(orderItem)">
                                <span>收起 </span> <img :src="packUp" />
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        style="margin-top: 10px"
                        v-if="param.total != 0"
                        v-model:current-page="param.pageNum"
                        v-model:page-size="param.pageSize"
                        :page-sizes="[5, 10, 15, 20]"
                        :small="small"
                        :disabled="disabled"
                        :background="background"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="param.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </div>
                <el-empty description="暂无数据" v-if="tableData.data.length == 0" />
            </div>
        </div>

        <el-dialog v-model="dialogTableVisible" title="我的供应商报价" :width="850">
            <el-table :data="supplierQuotationList.data" :border="true">
                <el-table-column type="index" width="50" label="序号" align="center" />
                <el-table-column property="supplierName" label="供应商名称" width="300" align="center" />
                <el-table-column property="supplierPrice" label="报价" width="150" align="center" />
                <el-table-column property="createTime" label="报价时间" width="200" align="center" />
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <span
                            @click="toOrder(scope.row)"
                            style="cursor: pointer; color: #0e3177ff"
                            v-if="scope.row.isPlaceAnOrder == 0"
                            >下单</span
                        >
                        <span style="cursor: pointer; color: #e99f27ff" v-else>已下单</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                style="margin-top: 10px"
                v-if="MemberPurchaseGoodsParam.total != 0"
                v-model:current-page="MemberPurchaseGoodsParam.pageNum"
                v-model:page-size="MemberPurchaseGoodsParam.pageSize"
                :page-sizes="[5, 10, 15, 20]"
                :small="small"
                :disabled="disabled"
                :background="background"
                layout="total, sizes, prev, pager, next, jumper"
                :total="MemberPurchaseGoodsParam.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-dialog>
        <el-dialog
            v-model="SupplierVisible"
            title="我的供应商"
            :width="710"
            :close-on-click-modal="false"
            :before-close="closeModal"
        >
            <div style="position: relative">
                <el-form ref="ruleFormRef" :model="mySupplierParam">
                    <el-form-item label="供应商名称" prop="supplierName">
                        <el-input v-model="mySupplierParam.supplierName" style="width: 195px" size="small" />
                    </el-form-item>
                    <div class="searchBtn2">
                        <div class="search" @click="onSubmits()">搜索</div>
                        <el-button @click="resetForms(ruleFormRef)" size="small">重置</el-button>
                    </div>
                </el-form>
            </div>

            <el-table
                :data="mySupplierList.data"
                v-model="mySupplierList.data"
                :border="true"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="40" />
                <el-table-column type="index" width="80" label="序号" align="center" />
                <el-table-column property="supplierName" label="供应商" width="400" align="center" />
                <!--<el-table-column
          prop="status"
          label="供应商状态"
          align="center"
          width="150"
        >
          <template #default="scope">
            <p
              v-if="scope.row.status >= 4"
              style="width: 80px; text-align: left; margin: 0 auto"
            >
              <span class="greenCircle"></span>
              <span>已入驻</span>
            </p>
            <p
              v-if="scope.row.status < 4"
              style="width: 80px; text-align: left; margin: 0 auto"
            >
              <span class="redCircle"></span>
              <span>未入驻</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="inviteDate"
          label="邀请时间"
          align="center"
          width="200"
        />-->
                <el-table-column label="操作" align="center" width="149">
                    <template #default="scope">
                        <span
                            style="color: #103276ff; margin-right: 10px; cursor: pointer"
                            @click="toSupplierHomePage(scope.row.id, scope.row.supplierName)"
                            v-if="scope.row.status >= 4"
                            >查看</span
                        >
                        <span style="color: #103276ff; cursor: pointer" @click="inviteQuote([scope.row.id])"
                            >邀请报价</span
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div style="display: flex; justify-content: center">
                <el-pagination
                    size="small"
                    class="mt-4"
                    style="margin-top: 10px"
                    v-if="mySupplierParam.total != 0"
                    v-model:current-page="mySupplierParam.pageNum"
                    v-model:page-size="mySupplierParam.pageSize"
                    :page-sizes="[10, 20, 30, 40]"
                    :small="small"
                    :disabled="disabled"
                    :background="background"
                    :total="mySupplierParam.total"
                    @size-change="handleSizeChanges"
                    @current-change="handleCurrentChanges"
                />
            </div>
            <template #footer>
                <div style="display: flex; justify-content: flex-end">
                    <div style="display: flex; margin-bottom: 10px">
                        <div class="search2" @click="addSupplier()">邀请供应商</div>
                        <div class="search3" @click="inviteQuote(SupplietParam?.supplierIdList)">邀请报价</div>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
    <el-dialog v-model="descImgVisible" title="查看图片" width="43%">
        <div style="display: flex; justify-content: flex-start">
            <el-image
                v-for="(url, index) in srcList"
                :key="index"
                :src="url"
                :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                :preview-src-list="srcList"
                :initial-index="4"
                fit="cover"
                style="width: 148px; height: 148px; margin-right: 15px"
            />
        </div>
    </el-dialog>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import { ElInput, ElMessage, ElMessageBox, ElTimeline, ElTimelineItem } from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";
import { platform } from "@/utils/config";
import unfold from "../../../assets/iconImages/unfold.png";
import packUp from "../../../assets/iconImages/packUp@2x.png";
import checkFiles from "../../../assets/iconImages/checkFiles.png";
export default {
    name: "order-list",
    components: {
        ElInput,
        ElTimeline,
        ElTimelineItem,
        SldCommonEmpty,
        MemberTitle
    },
    setup() {
        // const balance = require("../../assets/buy/balance.png");

        const store = useStore();
        const value5 = ref("");
        const router = useRouter();
        const route = useRoute();
        const memberInfo = reactive({ data: store.state.memberInfo });
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const current_state = ref(""); //当前列表订单状态
        const evaluate_state = ref(0); //是否是待评价
        const ruleFormRef = ref();
        const orderSn = ref(""); //订单id
        let orderProductObj = reactive({});
        let orderProductList = reactive({ data: [] }); //确认收货展示数据
        const tableData = reactive({ data: [] });
        const deptList = reactive({ data: [] });
        const activeName = ref("");
        const mySupplierList = reactive({ data: [] });
        const descImgVisible = ref(false); //备注中图片弹窗
        const MemberPurchaseGoodsList = reactive({ data: [] });
        const srcList = ref([]); //备注中图片url集合
        const createOrderParam = reactive({
            memberId: store.state.memberInfo.memberId,
            purchasePlanCode: "",
            orderRemark: "",
            extMemberOrderDetailList: [
                {
                    id: "",
                    memberNeedCode: "",
                    supplierUserId: ""
                }
            ]
            //非必须
            //item 类型: object

            //id	number
            //非必须
            //采购计划报价商品表 id
            //memberNeedCode	string
            //非必须
            //需求单号
            //supplierUserId
        });
        const SupplietParam = reactive({
            extMemberOrderDetailList: [
                {
                    id: "",
                    memberNeedCode: "",
                    supplierUserId: ""
                }
            ],
            supplierIdList: [],
            memberNeedCode: []
        });
        const MemberPurchaseGoodsParam = reactive({
            memberId: store.state.memberInfo.memberId,
            memberNeedCode: "",
            pageNum: 1,
            pageSize: 10,
            total: 0
        });
        const param = reactive({
            memberId: store.state.memberInfo.memberId,
            memberPlanCode: "",
            date: "",
            prayName: "",
            time: "",
            state: "",
            pageNum: 1,
            pageSize: 10,
            total: 0
        });
        const mySupplierParam = reactive({
            memberId: store.state.memberInfo.memberId,
            supplierName: "",
            pageNum: 1,
            pageSize: 10,
            total: 0,
            memberNeedCode: [],
            supplierIdList: []
        });
        const pageData = reactive({
            pageNum: 1,
            pageSize: 10,
            total: 0
        });

        const dialogTableVisible = ref(false);
        const supplierQuotationList = reactive({ data: [] });
        const SupplierVisible = ref(false);
        const confirmReceipt = () => {
            ElMessageBox.confirm("是否确认下单？", L["提示"], {
                confirmButtonText: L["确定"],
                cancelButtonText: L["取消"],
                type: "warning"
                //center: true,
            })
                .then(() => {
                    proxy
                        .$post("v3/business/front/orderOperate/receive", {
                            orderSn
                        })
                        .then((res) => {
                            if (res.state == 200) {
                                ElMessage.success("操作成功");
                                getOrderList();
                            } else {
                                ElMessage(res.msg);
                            }
                        })
                        .catch(() => {
                            //异常处理
                        });
                })
                .catch(() => {});
        };

        const onSubmit = async (formEl) => {
            if (!formEl) return;
            await formEl.validate((valid, fields) => {
                if (valid) {
                    const paramss = { ...param };

                    paramss.time = "";

                    param.time = formEl.model.time;
                    if (paramss.source && paramss.source == 1) {
                        paramss.source = platform;
                    }
                    getOrderList(paramss);
                } else {
                    console.log("error submit!", fields);
                }
            });
        };
        const onSubmits = () => {
            //const paramss = { ...mySupplierParam };

            //paramss.time = "";

            //param.time = formEl.model.time;
            //if (paramss.source && paramss.source == 1) {
            //  paramss.source = platform;
            //}
            mySupplier(mySupplierParam);
        };
        const toDetail = (code, state, isFillIn, goodsNum) => {
            console.log(code);

            router.push({
                path: "/member/procurement/monthlylist",
                query: {
                    memberPlanCode: code,
                    state: state,
                    goodsNum: goodsNum,
                    pageNum: param.pageNum,
                    pageSize: param.pageSize
                }
            });
            //router.push({
            //  path: "/member/procurement/procuremmentPlanDetail",
            //  query: {
            //    memberPlanCode: code,
            //    state: state,
            //  },
            //});
        };
        const resetForm = (formEl) => {
            if (!formEl) return;
            formEl.resetFields();
            param.pageNum = 1;
            param.time = "";
            param.date = "";

            getOrderList(param);

            //param.resetFields()
            //param.memberPlanCode= "",
            // param. source= "",
            // param. prayName= "",
            // param. startDate= "",
            // param. endDate= "",
            //  param.resource= "",
            //  param.state= "",
            //getOrderList()
        };
        const resetForms = (formEl) => {
            if (!formEl) return;
            formEl.resetFields();
            mySupplierParam.pageNum = 1;
            mySupplierParam.time = "";
            mySupplierParam.startDate = "";
            mySupplierParam.endDate = "";
            mySupplier(mySupplierParam);

            //param.resetFields()
            //param.memberPlanCode= "",
            // param. source= "",
            // param. prayName= "",
            // param. startDate= "",
            // param. endDate= "",
            //  param.resource= "",
            //  param.state= "",
            //getOrderList()
        };
        const getOrderList = (params) => {
            console.log(params);
            params.deptId = activeName.value;
            proxy.$get("api/shopp/memberPurchasePlan/queryPage", params).then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    console.log(res.total);
                    tableData.data = res.data.records;
                    if (res.data.pageSize != 0) {
                        param.pageSize = res.data.pageSize;
                        param.pageNum = res.data.pageNum;
                        param.total = res.data.total;
                        console.log(param.total);
                        tableData.data.forEach((item) => {
                            item.num = 4;
                        });
                    } else {
                        param.pageSize = 10;
                        param.pageNum = 1;
                        param.total = 0;
                    }

                    //cartLen.value = res.data.count ? res.data.count : 0
                } else {
                    ElMessage.error(res.msg);
                }

                //cartLen.value = res.data.count ? res.data.count : 0
            });
        };
        const addPlan = () => {
            router.push("/member/procurement/addedMonthly");
        };
        const newdepartments = () => {
            console.log("新增");
        };
        const handleSizeChange = (val) => {
            param.pageSize = val;
            getOrderList(param);
            console.log(`${val} items per page`);
        };
        const handleCurrentChange = (val) => {
            param.pageNum = val;
            getOrderList(param);
            console.log(`current page: ${val}`);
        };
        const handleSizeChanges = (val) => {
            mySupplierParam.pageSize = val;
            mySupplier(mySupplierParam);
            console.log(`${val} items per page`);
        };
        const handleCurrentChanges = (val) => {
            mySupplierParam.pageNum = val;

            mySupplier(mySupplierParam);
        };
        const getDeptList = () => {
            proxy.$get("api/shopp/dept/query/list").then((res) => {
                //原接口v3/member/front/dept/list
                if (res.code == 200) {
                    deptList.data = res.data;
                    deptList.data.unshift({
                        deptId: "",
                        deptName: "全部计划"
                    });
                }
            });
        };
        const changeTab = (deptId) => {
            activeName.value = deptId;
            getOrderList(param);
        };
        const spreadGoodsList = (item) => {
            if (item.num == 4) {
                item.num = item.memberPurchaseNeedRespList.length;
            } else {
                item.num = 4;
            }
        };
        const getmySupplierList = (memberNeedCode, list) => {
            mySupplier(mySupplierParam);

            setTimeout(() => {
                if (memberNeedCode !== null) {
                    SupplietParam.memberNeedCode = [memberNeedCode];
                } else {
                    list.forEach((item) => {
                        console.log(item.memberNeedCode, 99999);
                        SupplietParam.memberNeedCode.push(item.memberNeedCode);
                    });

                    SupplietParam.memberNeedCode = [...new Set(SupplietParam.memberNeedCode)];
                }
                SupplierVisible.value = true;
            }, 700);
        };

        const mySupplier = (Param) => {
            //Param.memberNeedCode = [];
            //Param.supplierIdList=[]
            proxy.$get("api/shopp/supplier/querySupplier", Param).then((res) => {
                if (res.code == 200) {
                    console.log(res.data.records);

                    mySupplierList.data = res.data.records;

                    mySupplierParam.pageNum = res.data.pageNum;
                    mySupplierParam.pageSize = res.data.pageSize;
                    mySupplierParam.total = res.data.total;
                    return mySupplierParam;
                }
            });
        };
        const toSupplierHomePage = (id, name) => {
            console.log(id, name);
            router.push({
                path: "/goods/list",
                query: {
                    supplierId: id,
                    supplierUserName: name
                }
            });
        };
        const inviteQuote = (list) => {
            if (list.length !== 0) {
                proxy
                    .$post(
                        "api/shopp/memberPurchasePlan/inviteMySupplier",
                        {
                            memberNeedCodeList: SupplietParam.memberNeedCode,
                            supplierIdList: list
                        },
                        "json"
                    )
                    .then((res) => {
                        if (res.code == 200) {
                            ElMessage({
                                message: "操作成功",
                                type: "success"
                            });
                            //SupplierVisible.value = false;
                            //SupplietParam.memberNeedCode = [];
                            SupplietParam.supplierIdList = [];
                            mySupplierParam.pageNum = 1;
                            mySupplierParam.pageSize = 10;
                        }
                    });
            } else {
                ElMessage({
                    message: "请选择供应商",
                    type: "warning"
                });
            }
        };
        const handleSelectionChange = (val) => {
            console.log(val);
            val.forEach((item) => {
                SupplietParam.supplierIdList.push(item.id);
            });
            SupplietParam.supplierIdList = [...new Set(SupplietParam.supplierIdList)];
        };
        const addSupplier = () => {
            router.push("/member/mySupplier/addSupplier");
        };
        const createOrder = (orderItem, goodsItem) => {
            createOrderParam.purchasePlanCode = orderItem.purchasePlanCode;
            createOrderParam.orderRemark = orderItem.orderRemark;
            createOrderParam.extMemberOrderDetailList[0].id = goodsItem.id;
            createOrderParam.extMemberOrderDetailList[0].memberNeedCode = goodsItem.memberNeedCode;
            MemberPurchaseGoodsParam.memberNeedCode = goodsItem.memberNeedCode;
            dialogTableVisible.value = true;

            getqueryMemberPurchaseGoodsList();
        };
        const getqueryMemberPurchaseGoodsList = () => {
            console.log(MemberPurchaseGoodsParam);
            proxy
                .$get("api/shopp/memberPurchasePlan/queryMemberPurchaseGoodsPage", MemberPurchaseGoodsParam, "json")
                .then((res) => {
                    if (res.code == 200) {
                        supplierQuotationList.data = res.data.records;

                        //      MemberPurchaseGoodsParam.pageNum = res.data.pageNum;
                        // MemberPurchaseGoodsParam.pageSize = res.data.pageSize;
                        //  MemberPurchaseGoodsParam.total = res.data.total;
                    }
                });
        };
        const close = (memberNeedCode, memberPlanCode) => {
            ElMessageBox.confirm("是否确认关闭？", L["提示"], {
                confirmButtonText: L["确定"],
                cancelButtonText: L["取消"],
                type: "warning"
                //center: true,
            })
                .then(() => {
                    proxy
                        .$get("api/shopp/purchase/plan/closePurchaseNeeds", {
                            memberNeedCode,
                            memberPlanCode
                        })
                        .then((res) => {
                            if (res.code == 200) {
                                ElMessage.success("操作成功");
                                getOrderList(param);
                            } else {
                                ElMessage(res.msg);
                            }
                        })
                        .catch(() => {
                            //异常处理
                        });
                })
                .catch(() => {});
        };
        const toOrder = (item) => {
            createOrderParam.extMemberOrderDetailList[0].id = item.id;
            createOrderParam.extMemberOrderDetailList[0].supplierUserId = item.supplierUserId;
            ElMessageBox.confirm("是否确认下单？", L["提示"], {
                confirmButtonText: L["确定"],
                cancelButtonText: L["取消"],
                type: "warning"
                //center: true,
            })
                .then(() => {
                    proxy
                        .$post("api/shopp/memberPurchasePlan/insertExtMemberOrder", createOrderParam, "json")
                        .then((res) => {
                            if (res.code == 200) {
                                ElMessage.success("操作成功");
                                getOrderList(param);
                                dialogTableVisible.value = false;
                            } else {
                                ElMessage(res.msg);
                            }
                        })
                        .catch(() => {
                            //异常处理
                        });
                })
                .catch(() => {});
        };
        //备注中查看图片
        const previewImg = (imgs) => {
            srcList.value = imgs.split(",");
            descImgVisible.value = true;
        };
        onMounted(() => {
            if (route.query.orderState) {
                current_state.value = route.query.orderState;
            } else {
                current_state.value = "";
            }
            if (route.query.evaluateState) {
                evaluate_state.value = route.query.evaluateState;
            } else {
                evaluate_state.value = "";
            }
            //  getTime();
            console.log(route.query.pageNum, route.query.pageSize);
            if (route.query.pageNum && route.query.pageSize) {
                param.pageNum = route.query.pageNum;
                param.pageSize = route.query.pageSize;
            }

            if (!(store.state.memberInfo.isLookOrder == false && store.state.memberInfo.memberUserType == 2)) {
                console.log(2222);
                getDeptList();

                //getOrderList(param);
            } else {
                console.log(111);
                console.log(store.state.memberInfo);
                deptList.data = [
                    {
                        deptId: store.state.memberInfo.deptId,
                        deptName: store.state.memberInfo.deptName
                    }
                ];
                activeName.value = store.state.memberInfo.deptId;
            }
            getOrderList(param);
            //  getCancelList();
            //  getAddressList();
        });
        const closeModal = () => {
            SupplierVisible.value = false;
            SupplietParam.memberNeedCode = [];
            SupplietParam.supplierIdList = [];
            mySupplierParam.pageNum = 1;
            mySupplierParam.pageSize = 10;
        };
        router.beforeEach((to, from, next) => {
            //  if (to.query.orderState) {
            //    current_state.value = to.query.orderState;
            //  } else {
            //    current_state.value = "";
            //  }
            //  if (to.query.evaluateState) {
            //    evaluate_state.value = to.query.evaluateState;
            //  } else {
            //    evaluate_state.value = "";
            //  }
            if (to.path == "/member/procurement/procurementPlanList") {
                getOrderList(param);
            }
            next();
        });
        return {
            L,
            getOrderList,
            onSubmit,
            param,
            tableData,
            toDetail,
            addPlan,
            newdepartments,
            resetForm,
            ruleFormRef,
            value5,
            pageData,
            handleSizeChange,
            handleCurrentChange,
            platform,

            dialogTableVisible,
            supplierQuotationList,
            confirmReceipt,
            SupplierVisible,
            deptList,
            activeName,
            changeTab,
            unfold,
            packUp,
            spreadGoodsList,
            mySupplier,
            mySupplierParam,
            mySupplierList,
            onSubmits,
            getmySupplierList,
            handleSizeChanges,
            handleCurrentChanges,
            resetForms,
            inviteQuote,
            toSupplierHomePage,
            handleSelectionChange,
            addSupplier,
            createOrder,
            getqueryMemberPurchaseGoodsList,
            MemberPurchaseGoodsParam,
            store,
            close,
            createOrderParam,
            toOrder,
            descImgVisible,
            previewImg,
            srcList,
            checkFiles,
            closeModal,
            SupplietParam
        };
    }
};
</script>
<style lang="scss">
@import "../../../style/monthlylist.scss";

.el-table th {
    background-color: #f9f9f9ff;
}
.sld_order_list .container {
    // position: relative;
    width: 1018px;
}

.el-pager li.active {
    color: $colorMain;
    cursor: default;
    font-size: 16px;
}

.look {
    color: rgb(74, 122, 211);
    cursor: pointer;
}

.el-pager li:hover {
    color: $colorMain;
}

.el-select-dropdown__item.selected {
    color: $colorMain;
}

.sld_order_list {
    color: #000;

    .sld_List {
        color: #000;
    }

    ::v-deep .el-dialog__header {
        background-color: $colorMain;
        padding: 11px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ::v-deep .el-dialog__title {
            color: #fff;
            font-size: 14px;
        }

        ::v-deep .el-dialog__headerbtn {
            top: auto;
        }

        ::v-deep .el-dialog__headerbtn .el-dialog__close {
            color: #fff !important;
            font-size: 18px;
        }
    }

    ::v-deep .el-dialog {
        overflow: hidden;
        border-radius: 4px;
    }

    ::v-deep.el-dialog__footer {
        display: flex;
        // justify-content: center;
        align-items: center;
    }

    .aft_btn:hover {
        background-color: rgba($color: #f30213, $alpha: 0.2);
        color: #f30213;
    }

    .aft_btn:focus {
        background-color: #fff;
        color: #333333;
        border-color: #dcdfe6;
    }

    .dia_btn {
        .el-button {
            width: 134px;
            height: 36px;
        }
    }

    .downLoad_contract {
        width: 150px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #e8e8f1;
        border-radius: 5px;
        cursor: pointer;
    }

    .downLoad_contract:hover {
        border: 1px solid $colorMain;
    }
}

.container {
    .bootom-footer {
        text-align: right;
    }

    .bootom-footer button:first-child {
        margin-right: 10px;
    }

    // .el-input {
    //   width: 300px;
    // }
    .unit {
        font-size: 18px;
        margin-left: 10px;
    }
}

.demo-form-inline .el-input {
    --el-input-width: 220px;
}

.el-table thead {
    color: #5f5f60;
}

.el-table {
    color: #2b2b2c;
}

.sld_order_button {
    margin-bottom: 10px;
    // border: #000 1px solid;
    position: relative;
}

.sld_order_newdepartments {
    position: absolute;
    top: 40px;
    right: 0;
    width: 90px;
    height: 30px;
    border: 2px solid #103277;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    color: #103277;
    cursor: pointer;
}

.el-radio {
    margin-right: 0 !important;
}

.button {
    background: #0e3177;
    cursor: pointer;
    width: 70px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    /*margin-left: 10px;*/
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
}

.button2 {
    background: #0e3177;
    cursor: pointer;
    width: 70px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    /*margin-left: 10px;*/
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
}

.button3 {
    // background: #0e3177;
    cursor: pointer;
    width: 70px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    // color: #fff;
    /*margin-left: 10px;*/
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
}

.headerSearch3 {
    position: relative;
}

.searchBtn {
    cursor: pointer;
    display: flex;
    width: 200px;
    position: absolute;
    top: 0;
    left: 85%;
}
.searchBtn2 {
    cursor: pointer;
    display: flex;
    /*width: 200px;*/
    position: absolute;
    top: 3%;
    right: 0%;
    .search {
        width: 56px;
        height: 32px;
        line-height: 32px;
        background: #0e3177;
        cursor: pointer;
        text-align: center;
        border-radius: 3px;
        color: #fff;
        margin-right: 10px;
    }
}
.search2 {
    width: 100px;
    height: 32px;
    line-height: 32px;
    background: #0e3177;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    margin-right: 10px;
}
.search3 {
    width: 100px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border: 1px solid #0e3177;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    color: #0e3177;
    margin-right: 10px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    /*background: #03499e;*/
}

::v-deep .el-date-editor .el-range-separator {
    width: 10%;
}

.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input__inner:focus {
    border-color: #409eff !important;
}

/*.member_info_container{
     padding: 0 0 0 0 !important;
  }*/
/*.el-input {
    width: 210px !important;
  }*/
.el-dialog__body {
    padding-top: 10px;
    padding-bottom: 20px;
}
.el-button--primary {
    --el-button-font-color: #ffffff;
    --el-button-background-color: #092e54;
    --el-button-border-color: #092e54;
    --el-button-hover-color: #092e54;
    --el-button-active-font-color: #e6e6e6;
    --el-button-active-background-color: #092e54;
    --el-button-active-border-color: #092e54;
}
.el-message-box--center .el-message-box__title {
    justify-content: start;
}
.el-message-box__content {
    text-align: left;
}
.el-message-box--center .el-message-box__btns {
    text-align: right;
}
.el-message-box__header {
    border-bottom: 1px solid #e5e7ec;
}
.Clear {
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #5e5858;
    line-height: 35px;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.Submit2 {
    background: #0e3177;
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 35px;
    font-size: 14px;
    cursor: pointer;
}
.desc-image__preview {
    width: 100%;
    height: 200px;
    position: relative;
    overflow-y: auto;
    display: flex;
    margin-right: 10px;
    .desc-image__preview_content {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}
</style>
